import React from 'react';
import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from 'gatsby';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQrcode} from '@fortawesome/pro-solid-svg-icons';
import Img from 'gatsby-image';

import BlogContainer from '../../components/Blog/BlogContainer';
import BlogCoverImage from '../../components/Blog/BlogCoverImage';
import BlogInlineImage from '../../components/Blog/BlogInlineImage';
import BlogShare from '../../components/Blog/BlogShare';

export default function StoJeQrKod({location}) {
	const data = useStaticQuery(graphql`
		query {
			coverImage: file(relativePath: {eq: "qr/qr-cover.png"}) {
				childImageSharp {
					# Specify a fixed image and fragment.
					# The default width is 400 pixels
					fluid(maxWidth: 1600) {
						...GatsbyImageSharpFluid
					}
				}
			}
			ogImage: file(relativePath: {eq: "qr/qr-cover.png"}) {
				childImageSharp {
					# Specify a fixed image and fragment.
					# The default width is 400 pixels
					fixed(width: 1080) {
						...GatsbyImageSharpFixed
					}
				}
			}
			authorImage: file(relativePath: {eq: "author/vlatko.jpg"}) {
				childImageSharp {
					fixed(width: 40) {
						...GatsbyImageSharpFixed
					}
				}
			}
			iosNotification: file(relativePath: {eq: "qr-scan-notification-ios.png"}) {
				childImageSharp {
					fixed(width: 280) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`);

	return (
		<BlogContainer
			id={1}
			ogImage={data.ogImage.childImageSharp.fixed}
			uri={location.pathname}
			authorImage={data.authorImage.childImageSharp.fixed}
		>
			<blockquote>
				Tri crna kvadratića sad već vidite svaki dan, gdje god krenete i gdje god pogledate: u trgovinama uz cijenu artikla, na
				fiskaliziranim računima, propusnicama, COVID putovnicama, Bitcoin novčanicima ili uređajima za naplatu parkinga - QR kȏd je tu. A
				znate li <strong>što je QR kȏd</strong>?
			</blockquote>
			<BlogCoverImage image={data.coverImage.childImageSharp.fluid} />
			<section>
				<BlogShare />
				<p>
					<strong>QR kȏd</strong> je tip matričnog (dvodimenzionalnog) koda koji je prvotno osmišljen za autoindustriju još davne 1994.
					godine u Japanu, ali je zbog svoje praktičnosti brzo prešao granice i sustav je postao popularan i van auto industrije.
				</p>
				<p>Za razliku od barkoda, glavne prednosti QR kȏda su:</p>
				<ul>
					<li>
						brzina dekodiranja je velika, zato kratica{' '}
						<em>
							<strong>QR</strong>
						</em>{' '}
						na engleskom i znači{' '}
						<em>
							<strong>Quick Response</strong>
						</em>
					</li>
					<li>
						može pohraniti više podataka (tj, više znakova) - ako se pohranjuju samo brojke, moguće je spremiti do 7089 znamenki, a ako se
						koristi alfanumerički niz znakova (koji je puno korisniji), onda je moguće spremiti do 4296 znakova
					</li>
					<li>ovisno o kvaliteti kodiranja, može dodati redundanciju što znači da će kȏd ostati čitljiv čak i ako se dio kȏda ošteti</li>
					<li>nebitno je kako je kȏd zarotiran pri čitanju - dekodiranje će i dalje raditi bez problema</li>
					<li>
						prilično je neosjetljiv na zakrivljenost i savijenost površine na kojoj se nalazi (do određene granice) što daje mogućnost da se
						postavi i na zakrivljene površine (npr. boce, čaše, vrećice...)
					</li>
				</ul>
				<p>
					<BlogInlineImage
						image="/blog/sto-je-qr-kod/drugaciji-qr-kod.svg"
						alt="Drugačiji oblik QR koda"
						width={200}
						height={200}
						position="right"
					/>
					Najpoznatiji oblik QR kodova su tri crna kvadrata na bijeloj pozadini i to ih čini najprepoznatljivijima, ali to ne mora biti
					slučaj. Ta tri kvadrata se nazivaju <em>oči</em> i služe kao orjentir algoritmu za skeniranje kako bi se ustanovilo kako je QR kȏd
					zarotiran i kako ga skenirati. <em>Oči</em> u QR kȏdu mogu biti i u drugim oblicima (npr. kružnica, kvadrat sa zaobljenim rubovima
					ili nešto drugo), ali ih mi ne preporučujemo jer nisu svi uređaji (tj. software na tim uređajima) sposobni pročitati sve te
					oblike, a i potrebno je nešto više vremena za detekciju <em>očiju</em>, a to znači i da samo skeniranje QR-a duže traje.
				</p>
				<h2>Kako skenirati QR kȏd?</h2>
				<p>
					Najjednostavniji način skeniranja je korištenjem pametnog telefona. Za vrijeme pisanja ovog članka (2021. godina) je situacija
					takva da koristeći iPhone ili iPad možete skenirati QR kod s <em>Camera</em> aplikacijom - to je aplikacija koju i inače koristite
					za fotografiranje - otvorite aplikaciju, usmjerite kameru prema QR kȏdu i ako je kȏd uspješno očitan, pojavit će vam se
					&quot;notifikacija&quot; s informacijom što je točno očitano. Ovako izgleda notifikacija uspješno očitanog QR kȏda na iOS
					uređajima:
				</p>
				<div className="text-center">
					<Img fixed={data.iosNotification.childImageSharp.fixed} />
				</div>
				<h3>Skeniranje na Android uređajima</h3>
				<p>
					Android uređaji jako ovise o proizvođaču uređaja, tako da neki imaju istu mogućnost skeniranja kao i Apple uređaji (otvorite
					kameru, usmjerite prema kodu i to je to). U slučaju da Vaš Android uređaj ne može očitati QR kȏd na taj način, onda preuzmite bilo
					koju aplikaciju sa Play Store-a za čitanje QR kodova. Čitanje QR kodova je potpuno besplatno, pa ako naletite na aplikaciju koja
					traži bilo kakav oblik plaćanja, nemojte ju koristiti.
				</p>
				<h3>Skeniranje bez mobilne aplikacije</h3>
				<p>
					Za skeniranje QR kodova na svom pametnom telefonu ne morate ni imati nikakvu aplikaciju instaliranu. Dovoljno je da u svom
					pregledniku otvorite adresu{' '}
					<a href="https://nas.qr-cjenik.hr" target="_blank">
						https://nas.qr-cjenik.hr
					</a>
					, uslikate QR kȏd koji vidite i dobit ćete poruku da li je kȏd uspješno skeniran ili ne.
				</p>
				<h3>Skeniranje s Viber-om</h3>
				<p>
					Ako koristite mobilne aplikacije za dopisivanje, velika je vjerojatnost da na svom pametnom telefonu već imati instaliran Viber. U
					tom slučaju, otvorite Viber, stisnite na <em>More</em> (ili <em>Više</em> ako je na hrvatskom jeziku) i nakon toga ćete u gornjem
					desnom kutu ekrana vidjeti ikonu za QR koja izgleda otprilike ovako: <FontAwesomeIcon icon={faQrcode} />. Pritiskom na tu ikonu će
					vam se otvoriti kamera koja je spremna skenirati QR kȏd koji želite.
				</p>
				<h2>Što se nalazi u QR kodu?</h2>
				<p>
					Nakon što je QR kȏd uspješno skeniran, uređaj kojim je skeniran kȏd će dobiti niz znakova koji je bio kodiran u kȏdu. Najčešće će
					to biti niz alfanumeričkih znakova koji vašem uređaju mogu nešto značiti, a i ne moraju, pa se može dogoditi da skeniranjem
					dobijete tekst koji Vam ne znači baš ništa.
				</p>
				<p>
					Postoje određeni standardi koji tumače što neki tekst može značiti ako počinje sa standardiziranim setom znakova. Npr. kada negdje
					vidite tekst koji zpočinje sa <strong>http://</strong>, odmah znate da se radi o URL-u. Ista stvar je sa QR kodovima. Ako QR kȏd u
					sebi sadržava tekst koji počinje sa <strong>http://</strong> ili sa <strong>https://</strong>, uređaj će odmah znati da se radi o
					URL-u i odmah će Vam ponuditi opciju da otvorite taj URL u svom pregledniku.
				</p>
				<p>
					Na taj način je moguće <em>ukodirati</em> tekst koji će biti bilo koji URL, vizitka (eng. vcard), email adresa, SMS poruka, podaci
					za spajanje na WiFi mrežu, broj telefona, lokacija na karti ili nešto drugo.
				</p>
				<p>
					Ako ste ikada vidjeli nečiju adresu Bitcoin novčanika, vidjet ćete da se adresa obično prikazuje kao QR kȏd. Kada bi skenirali taj
					QR kȏd, dobili bi niz alfanumeričkih znakova koji Vama osobno ništa ne znači, ali u kontekstu Bitcoina, taj niz znakova se može
					tumačiti kao adresa novčanika.
				</p>
				<p>
					Adresa Bitcoin novčanika je uvijek izražena kao neki niz znakova i nikako nije povezana s QR tehnologijom. QR kȏd je u tom slučaju
					samo pomoćni alat koji služi za brzo dijeljenje tog niza znakova između uređaja bez da se izgubi bilo kakva informacija. Jer, da
					ručno prepisujete taj niz znakova, velika je mogućnost da ćete pogriješiti, a i samo prepisivanje dugačkih nizova znakova je -
					sporo.
				</p>
				<h2>Kako napraviti QR kȏd?</h2>
				<p>
					QR kȏd može napraviti svatko koristeći bilo koju{' '}
					<a href="https://duckduckgo.com/?q=qr+code+generator" target="_blank" rel="noreferrer noopener">
						web stranicu
					</a>{' '}
					koja se bavi generiranjem QR kȏdova, pitanje je samo, što želite staviti u QR kȏd?
				</p>
				<p>
					Jednom kada se QR kȏd kreira, on se više ne može promijeniti. Česta greška koja se događa je da u QR kodovima bude stavljen URL
					koji u početku radi i vodi na ispravnu adresu, ali se onda nešto promijeni na domeni na koju vodi i URL prestane raditi. Samim
					time, skeniranje QR kȏda vodi na nepostojeći URL. Ako imate QR kȏd u svom poslovnom prostoru koji vodi na vaš cjenik, pobrinite se
					da URL nastavi raditi, jer ćete u suprotnom morati izraditi novi QR kȏd svaki put kad se nešto dogodi s vašim URL-ovima. Ako ćete
					koristiti našu uslugu QR-Cjenika, onda taj problem nećete imati jer će Vam sustav dati jedinstveni URL koji se nikada neće
					promijeniti, a uz to ćete dobiti i statistiku koliko puta je skeniran vaš QR kȏd.
				</p>
				<p>
					Quick Response (QR) kodovi su odlična tehnologija za brzo skeniranje bilo kakvog teksta i svatko ih može napraviti i imati, ali
					najbitniji dio ove priče je ono što se događa nakon što je QR kȏd skeniran, a nema ništa gore nego kada Vašem potencijalnom
					klijentu stvorite frustraciju s QR kȏdom koji više ne vodi - nigdje.
				</p>
			</section>
		</BlogContainer>
	);
}

StoJeQrKod.propTypes = {
	// eslint-disable-next-line
	location: PropTypes.object
};
