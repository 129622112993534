import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';

export default function BlogInlineImage({image, position, width, height, alt}) {
	return (
		<>
			{typeof image === 'string' ? (
				<Image src={image} alt={alt} width={width} height={height} $width={width} $height={height} $position={position} />
			) : (
				<GatsbyImage fixed={image} alt={alt} width={width} height={height} $width={width} $height={height} $position={position} />
			)}
		</>
	);
}

BlogInlineImage.propTypes = {
	// eslint-disable-next-line
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired, // fixed image
	position: PropTypes.oneOf(['left', 'center', 'right']).isRequired,
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
	alt: PropTypes.string
};

const Image = styled.img`
	display: block;
	width: ${({$width}) => `${$width}px`};
	width: ${({$height}) => `${$height}px`};
	float: ${({$position, $width}) => {
		if ($position === 'center') {
			return 'none';
		}

		if ($width >= 80) {
			// if bigger than 80, we need to break on mobile
			return 'none';
		}

		return $position;
	}};

	margin: ${({$position, $width}) => {
		if ($width >= 80) {
			// if bigger than 80, we need to break on mobile
			return '1rem auto';
		}

		if ($position === 'right') {
			return '0 0 0.5rem 1rem';
		}

		if ($position === 'left') {
			return '0 1rem 0.5rem 0';
		}

		return '0';
	}};

	@media (min-width: 767px) {
		float: ${({$position}) => {
			if ($position === 'center') {
				return 'none';
			}

			return $position;
		}};

		margin: ${({$position}) => {
			if ($position === 'right') {
				return '0 0 0.5rem 1rem';
			}

			if ($position === 'left') {
				return '0 1rem 0.5rem 0';
			}

			return '0';
		}};
	}
`;

const GatsbyImage = Image.withComponent(Img);
